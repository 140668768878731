import { DecimalPipe } from '@angular/common';
import { Component,OnInit } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { Injectable } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { FooterRowOutlet } from '@angular/cdk/table';
import {Observable} from 'rxjs';
import { HttpClient, HttpResponse, HttpHeaders } from '@angular/common/http';
import { ThrowStmt } from '@angular/compiler';

interface PostedStack {
  id: string;
  stack: number[];
}
interface Stack {
  stack: number[];
}

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})


export class AppComponent  {
  title = 'rpncalc';
  DisplayValue = "0.00";
  DisplayEditing = false;
  DisplayDecimal = false;
  RootUrl = "https://api.mistersquawk.com/calc/stack/";
  BaseUrl = "";
  StackValue = "0";

  constructor(private httpClient: HttpClient) { }

  sendPostRequest(url: string, data: any): Observable<any> {
    return this.httpClient.post<PostedStack>(url, data) ;
  }
  sendPutRequest(url: string, data:any): Observable<any> {
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    return this.httpClient.put<Stack>(url, data, {headers: headers});
  }
  sendGetRequest(url: string): Observable<Stack> {  
    return this.httpClient.get<Stack>(url);
  }

  newStack(){
    this.sendPostRequest(this.RootUrl, { "stack" : [0] }).subscribe(
      (data: PostedStack) => {
        console.log("POST to " + this.RootUrl + " responded with " + data.id );
        this.BaseUrl = this.RootUrl + data.id.toString() + "/";
        sessionStorage.setItem("BaseUrl", this.BaseUrl);
      } );
      this.StackValue = "0";
      sessionStorage.setItem("StackValue", this.StackValue);
      sessionStorage.setItem("DisplayValue", "0");
  }
  ngOnInit(){


    if ( ! sessionStorage.getItem("DisplayValue") )
      {
      this.newStack();
      }
      else
      {
        console.log("Loading storage");
        this.DisplayValue = sessionStorage.getItem("DisplayValue") !;

        this.DisplayDecimal = (sessionStorage.getItem("DisplayDecimal") == 'true' ? true : false);
        this.DisplayEditing = (sessionStorage.getItem("DisplayEditing") == 'true' ? true : false);

        this.BaseUrl = sessionStorage.getItem("BaseUrl") !;
        this.StackValue = sessionStorage.getItem("StackValue") !;
       }

  }

onOp(op: string){

  var opUrl: string;
  var LocalStack: number[] = [0]; 


  if (op == "enter")
    opUrl = "";
  else
    opUrl = op + "/";

  // GET the current stack 
  //
  this.sendGetRequest(this.BaseUrl).subscribe(
    (data: Stack) => {
      // Push the current entry value and PUT the updated stack
      //
      LocalStack = data.stack;
      LocalStack.splice(0,0,parseFloat(this.DisplayValue));
      this.sendPutRequest(this.BaseUrl, { "stack" :  LocalStack } ).subscribe(
        (data1: Stack) => {
          // GET the stack from the requested operand's endpoint (or the stack, if <ENTER> was pushed)
          //
          console.log("PUT " + LocalStack + " TO " + this.BaseUrl );
          this.sendGetRequest(this.BaseUrl + opUrl ).subscribe(
              (data2: Stack) => {
                console.log ("GET " + data2.stack + " FROM " + this.BaseUrl + opUrl);
                LocalStack = data2.stack;
                this.StackValue = LocalStack.toString() ;
                this.DisplayValue = data2.stack[0].toString();
                // PUT the updated stack 
                //
                if (op != "enter")
                  this.sendPutRequest(this.BaseUrl, { "stack" :  LocalStack }).subscribe(
                    (data3: Stack) => {
                      console.log("PUT " + LocalStack + " TO " + this.BaseUrl );
                    } );
              } );         
          } );
      } ); 
}

onUnaryOp(op: string){

  var opUrl: string;
  var LocalStack: number[] = [0]; 


  opUrl = op + "/";

  // GET the current stack 
  //
  this.sendGetRequest(this.BaseUrl).subscribe(
    (data: Stack) => {
          this.sendGetRequest(this.BaseUrl + opUrl ).subscribe(
              (data2: Stack) => {
                console.log ("unary GET " + data2.stack + " FROM " + this.BaseUrl + opUrl);
                LocalStack = data2.stack;
                this.StackValue = LocalStack.toString() ;
                this.DisplayValue = data2.stack[0].toString();
                // PUT the updated stack
                //
                this.sendPutRequest(this.BaseUrl, { "stack" :  LocalStack }).subscribe(
                  (data3: Stack) => {
                    console.log("unary PUT " + LocalStack + " TO " + this.BaseUrl );
                    this.StackValue = LocalStack.toString() ;
                  } );
              } );         
          } );
}


  // First row

  onEnter() {
    this.stopEditing();
    this.saveEditingState();
    this.onOp("enter");
  }

  onXxY() {
    this.stopEditing();
    this.saveEditingState();
    this.onOp("y2x");
  }

  onCLX() {
    this.DisplayValue = "0.00"
    this.stopEditing();
    this.saveEditingState();
  }

  onCLR() {
    this.DisplayValue = "0.00"
    this.stopEditing();
    this.saveEditingState();
    this.newStack();
  }

  onSub() {
    this.stopEditing();
    this.saveEditingState();
    this.onOp("sub");
  }

  onAdd() {
    this.stopEditing();
    this.saveEditingState();
    this.onOp("add"); 
  }

  onMul() {
    this.stopEditing();
    this.saveEditingState();
    this.onOp("mul");
  }
  
  onDiv() {
    this.stopEditing();
    this.saveEditingState();
    this.onOp("div");
  }

  // Numeric entry

  onDigit(val: number ) {
    if (! this.DisplayEditing){
      this.startEditing();
    }
    this.DisplayValue = this.DisplayValue + val.toString();
    sessionStorage.setItem("DisplayValue", this.DisplayValue);
   }
   
   onDecimal() {
    if (! this.DisplayEditing){
      this.startEditing();
    }    
    if (! this.DisplayDecimal){
      this.DisplayValue = this.DisplayValue + ".";
      this.DisplayDecimal = true;
    }
    if(this.DisplayValue[0] == ".")
      this.DisplayValue = "0" + this.DisplayValue;
    this.saveEditingState();
  }

  onCHS() {
    if(this.DisplayEditing){
      if (parseInt(this.DisplayValue) != 0){
        if(this.DisplayValue[0] == "-")
          this.DisplayValue = this.DisplayValue.substring(1);
        else
          this.DisplayValue = "-" + this.DisplayValue;
      }
    }
    else{
      this.onUnaryOp("chs");
    }
  }

  startEditing(){
    if (! this.DisplayEditing){
      this.DisplayValue = "";
      this.DisplayEditing = true;
      this.DisplayDecimal = false;
    }   
  }
  
  stopEditing(){
      this.DisplayEditing = false;   
  }
  
 saveEditingState(){
    sessionStorage.setItem("StackValue", this.StackValue);
    sessionStorage.setItem("DisplayValue", this.DisplayValue);
    sessionStorage.setItem("DisplayDecimal", this.DisplayDecimal.toString());
    sessionStorage.setItem("DisplayEditing", this.DisplayEditing.toString());
  }

}
