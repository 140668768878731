
<link href='https://fonts.googleapis.com/css?family=Roboto Mono' rel='stylesheet' type='text/css'>

<div class="buttonrow" role="main">

  <div style="font-family: 'Roboto Mono', sans-serif; font-size:25px  ; font-weight: regular ;">
  <td title="{{StackValue}}" style="padding: 5px; background-color: lightgray; width: 320px; text-align: right; margin-block: 10px;">{{DisplayValue}}</td>
 </div>



  <div class="buttonrow" >
    <button (click)="onEnter()" mat-raised-button class="b_enter" color="primary">ENTER</button>
    <button (click)="onXxY()" mat-raised-button class="b_cmd" color="accent">Y^X</button>
    <button (click)="onCLX()" mat-raised-button class="b_cmd" color="accent">CLX</button>
    <button (click)="onCLR()" mat-raised-button class="b_cmd" color="warn">CLR</button>
  </div>
  <div class="buttonrow" >
    <button (click)="onSub()" mat-raised-button class="b_op" color="primary">-</button>
    <button (click)="onDigit(7)" mat-raised-button class="b_digit" color="basic">7</button>
    <button (click)="onDigit(8)" mat-raised-button class="b_digit" color="basic">8</button>
    <button (click)="onDigit(9)" mat-raised-button class="b_digit" color="basic">9</button>
  </div>
  <div class="buttonrow" >
    <button (click)="onAdd()" mat-raised-button class="b_op" color="primary">+</button>
    <button (click)="onDigit(4)" mat-raised-button class="b_digit" color="basic">4</button>
    <button (click)="onDigit(5)" mat-raised-button class="b_digit" color="basic">5</button>
    <button (click)="onDigit(6)" mat-raised-button class="b_digit" color="basic">6</button>
  </div>
  <div class="buttonrow" >
    <button (click)="onMul()" mat-raised-button class="b_op" color="primary">x</button>
    <button (click)="onDigit(1)" mat-raised-button class="b_digit" color="basic">1</button>
    <button (click)="onDigit(2)" mat-raised-button class="b_digit" color="basic">2</button>
    <button (click)="onDigit(3)" mat-raised-button class="b_digit" color="basic">3</button>
  </div>
  <div class="buttonrow" >
    <button (click)="onDiv()" mat-raised-button class="b_op" color="primary">/</button>
    <button (click)="onDigit(0)" mat-raised-button class="b_digit" color="basic">0</button>
    <button (click)="onDecimal()" mat-raised-button class="b_digit" color="basic">.</button>
    <button (click)="onCHS()" mat-raised-button class="b_digit" color="basic">CHS</button>
  </div>

</div>

  

<router-outlet></router-outlet>